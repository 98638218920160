import { initStopAnimateOnResize } from './components/initStopAnimateOnResize';
import { initFunnelParams } from './components/initFunnelParams';
import { initLangSwitcher } from './components/initLangSwitcher';

document.addEventListener('DOMContentLoaded', handleOnLoadPage);

function handleOnLoadPage() {
    initFunnelParams();
    initStopAnimateOnResize();
    initLangSwitcher();
}
