import { document } from 'mixpanel-browser/src/utils';

export const initLangSwitcher = () => {
    // VARS
    const button = document.querySelector('[data-lang-switcher-button]');
    if (!button) return;
    const switcher = document.querySelector('[data-lang-switcher]');
    const links = switcher.querySelectorAll('a');
    const visibleClass = '--visible';

    // LISTENERS
    button.addEventListener('click', handleToggleSwitcher);
    document.addEventListener('click', function (event) {
        if (!event.target.closest('[data-lang-switcher]')) {
            close();
        }
    });

    // HANDLERS
    function handleToggleSwitcher() {
        if (switcher.classList.contains(visibleClass)) {
            close();
        } else {
            open();
        }
    }

    // FUNCTIONS
    function open() {
        switcher.classList.add(visibleClass);
        addTabIndex();
    }

    function close() {
        switcher.classList.remove(visibleClass);
        removeTabIndex();
    }

    function addTabIndex() {
        links.forEach((link) => {
            link.tabIndex = 0;
        });
    }

    function removeTabIndex() {
        links.forEach((link) => {
            link.tabIndex = -1;
        });
    }
};
